<template>
  <BSpinner v-if="isLoading" />
  <div class="px-6 pt-4 pb-12 flex flex-col min-h-screen text-sm">
    <div class="flex items-center mb-14">
      <button class="focus:outline-none" @click="$router.go(-1)">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <p class="text-center w-full font-semibold text-lg truncate pl-5 pr-10">
        Organisation Registration
      </p>
    </div>
    <OrganizationIcon class="h-24" />
    <form @submit.prevent="handleSubmit">
      <h2
        class="mb-6 mt-10 text-center text-text-primary font-semibold text-lg"
      >
        Are you an Organisation?
      </h2>
      <p class="text-center text-xs font-medium text-text-alternate-3">
        To register as an organisation, please send your application using the
        form below, we will contact you within 24 hours.
      </p>
      <div class="mt-8">
        <AuthInput
          label="Name of Organisation"
          v-model="orgname"
          type="text"
          :required="true"
        />
        <AuthInput
          label="Contact Person"
          v-model="contactperson"
          type="text"
          :required="true"
        />
        <AuthInput
          label="Contact Person’s Email"
          v-model="contactpersonemail"
          type="email"
          :required="true"
        />

        <AuthInput
          label="Contact Person’s Phone"
          v-model="contactpersonphone"
          type="number"
          :required="true"
        />
        <AuthInput
          label="Website (Optional)"
          v-model="website"
          type="text"
          :required="false"
        />
      </div>
      <button
        class="bg-btn-bg-primary text-btn-text-primary rounded w-full mt-8 py-4 font-bold"
        type="submit"
        :disabled="!isFilled"
      >
        Continue
      </button>
    </form>
  </div>
  <BModal v-if="isFailure" @close="closeFailureModal">
    <div class="text-center flex flex-col items-center">
      <FailureIcon class="my-4 mx-auto" />
      <h2 class="text-xl text-text-primary font-semibold my-4">Sorry!</h2>
      <p class="text-xs text-alternate-3">
        {{ error }}
      </p>
      <button
        class="bg-btn-bg-primary max-w-sm text-btn-text-primary rounded w-full mt-10 py-4 font-bold text-sm focus:outline-none"
        @click="closeFailureModal"
      >
        Retry
      </button>
    </div>
  </BModal>
  <BModal v-else-if="isSuccess" @close="closeSuccessModal">
    <div class="text-center flex flex-col items-center pb-6">
      <SuccessIcon class="mb-8 mt-4 mx-auto" />
      <p class="text-lg max-w-sm text-text-primary font-semibold my-4">
        Your application was sent successfully.
      </p>
      <p class="text-xs text-text-alternate-3 font-medium">
        We will get back to you within 24hours.
      </p>
    </div>
  </BModal>
</template>

<script>
import { reactive, toRefs, computed } from 'vue';
import AuthInput from '@/components/ui/AuthInput';
import { useRouter } from 'vue-router';
import BModal from '@/components/ui/BModal';
import BSpinner from '@/components/ui/BSpinner';
import { useApi } from '@/cmp-functions/useApi';
import { registerCompany } from '@/services/api';

export default {
  name: 'OrganizationRegistration',

  components: {
    BModal,
    BSpinner,
    AuthInput,
  },

  setup() {
    const companyDetails = reactive({
      contactperson: '',
      contactpersonphone: '',
      orgname: '',
      website: '',
      contactpersonemail: '',
    });

    const [response, authCompany, { setIsFailure }] = useApi(registerCompany);
    const closeFailureModal = () => {
      setIsFailure(false);
    };
    const router = useRouter();
    const closeSuccessModal = () => {
      router.push('/');
    };

    const handleSubmit = () => {
      authCompany(companyDetails);
    };

    const isFilled = computed(() => {
      const { contactperson, contactpersonphone, orgname, contactpersonemail } = companyDetails;
      return (
        contactperson && contactpersonphone && orgname && contactpersonemail
      );
    });

    return {
      ...toRefs(companyDetails),
      ...toRefs(response),
      closeFailureModal,
      isFilled,
      closeSuccessModal,
      handleSubmit,
    };
  },
};
</script>
